import React, { useState } from 'react';
import Header from '../../../common/Header';
// import { Link } from 'react-router-dom';
import '../../../common/modal/Modal';
import { call } from '../../../service/ApiService';

function GroupFile() {
    const [ isOpen, setIsOpen ] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const [ createGroupResponseDto, setCreateGroupResponseDto ] = useState({});

    const handleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
        if (isModalOpen) {
            setIsOpen("open")
            setIsModalOpen(!isModalOpen);
        } else {
            setIsOpen("")
            setIsModalOpen(!isModalOpen);
        }
    };
    
    const handleCloseModal = () => {
        setIsModalOpen(!isModalOpen);
        if (!isModalOpen) {
            setIsOpen("")
            setIsModalOpen(!isModalOpen);
        }
    };

    const setResponseDto = (e) => {
        setCreateGroupResponseDto({
            ...createGroupResponseDto,
            "userIdx": user.userId,
            "groupCapacity": 4,
            "groupName": e.target.value
        })
    };

    const createGroup = () => {
        call("/groups/group", "POST", createGroupResponseDto)
        .then((res) => {
            if (res.statusCode === 200) {
                alert("Success");
            } else {
                alert("Failed");
            }
        })
    };

    return (
        <div>
            <Header />
            <div className = "container" style = {{}}>
                <div className = "row">
                    <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className = "row">
                            <div className = "container" style = {{ width: "80%" }}>
                                {/* <Link to = "/files/group/create"><button className = "btn btn-outline-info" style = {{ float: "right" }} onClick = { handleOpenModal }>그룹 생성</button></Link> */}
                                <button className = "btn btn-outline-info" style = {{ float: "right" }} onClick = { handleOpenModal }>그룹 생성</button>
                                <div className = { `row createModal ${ isOpen }` } style = {{ paddingTop: "0.4vw" }}>
                                    <label style = {{ borderBottom: "solid 1px gray", display: "flex", justifyContent: "center", alignItems: "center" }}>그룹 만들기</label>
                                    <div className = "col-md-3 col-lg-3 col-xl-3 col-xxl-3" style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <label>그룹 명</label>
                                    </div>
                                    <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8" style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <input type = "text" className = "form-control" onChange = { setResponseDto } />
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <button type = "button" className = "btn btn-outline-info" style = {{ width: "100%" }} onClick = { createGroup }>생성</button>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <button type = "button" className = "btn btn-outline-danger" onClick = { handleCloseModal } style = {{ width: "100%" }}>취소</button>
                                    </div>
                                </div>
                                <div className = "row">
                                    <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                        <label>내 그룹</label>
                                        <hr />
                                        <div className = "row">
                                            <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3" style = {{ border: "solid 1px black", width: "30%", height: "6vh", borderRadius: "55%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                IMG
                                            </div>
                                            <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                                <ul style = {{ listStyle: "none" }}>
                                                    <li>그룹 명</li>
                                                    <li>그룹 인원</li>
                                                    <li>그룹 참여자</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GroupFile;